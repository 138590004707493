import cx from 'classnames';
import type { PageProps } from 'gatsby';
import { graphql } from 'gatsby';
import { Link, Trans, useI18next } from 'gatsby-plugin-react-i18next';
import srcPartnerApplePay from '../../assets/academy/apple-pay-logo.svg';
import srcOgImage from '../../assets/academy/campaign-og-image.png';
import srcCopnversionUpText from '../../assets/academy/conversion-up.svg';
import srcPartnerECCube from '../../assets/academy/ec-cube.svg';
import srcFakeVideo from '../../assets/academy/fake-video.png';
import srcPartnerGooglePay from '../../assets/academy/google-pay-logo.svg';
import srcGreenText from '../../assets/academy/green-text.svg';
import srcHeroShout from '../../assets/academy/hero-shout.svg';
import srcHeroSubtitle from '../../assets/academy/hero-subtitle.svg';
import srcHeroTitle from '../../assets/academy/hero-title.svg';
import srcLearnAndSale from '../../assets/academy/learn-and-sale.svg';
import srcPartnerMaster from '../../assets/academy/master-logo.svg';
import srcVoiceA from '../../assets/academy/people-voice-a.png';
import srcVoiceB from '../../assets/academy/people-voice-b.png';
import srcVoiceC from '../../assets/academy/people-voice-c.png';
import srcVoiceD from '../../assets/academy/people-voice-d.png';
import srcVoiceMobileA from '../../assets/academy/people-voice-mobile-a.png';
import srcVoiceMobileB from '../../assets/academy/people-voice-mobile-b.png';
import srcVoiceMobileC from '../../assets/academy/people-voice-mobile-c.png';
import srcVoiceMobileD from '../../assets/academy/people-voice-mobile-d.png';
import srcPinkText from '../../assets/academy/pink-text.svg';
import srcPurpleText from '../../assets/academy/purple-text.svg';
import srcPartnerShopify from '../../assets/academy/shopify-logo.svg';
import srcPartnerSMBC from '../../assets/academy/smbc-vc-logo.svg';
import srcPartnerVISA from '../../assets/academy/visa-logo.svg';
import srcVisionLogo from '../../assets/academy/vision-logo.svg';
import srcVisionTitle from '../../assets/academy/vision-title.svg';
import srcVision from '../../assets/academy/vision.png';
import srcYellowTextMobile from '../../assets/academy/yellow-text-horizontal.svg';
import srcYellowText from '../../assets/academy/yellow-text.svg';
import srcZeroFee from '../../assets/academy/zero-fee.svg';
import srcLogo from '../../assets/logotype.svg';
import Footer from '../../components/Academy/Footer';
import SEO from '../../components/SEO';
import styles from './index.module.scss';

type DataProps = {
  site: {
    siteMetadata: {
      siteUrl: string;
    };
  };
};

export const CampaignLandingAcademyPageQuery = graphql`
  query CampaignLandingAcademyPageQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

const CampaignLandingAcademyPage = ({ data }: PageProps<DataProps>) => {
  const { t } = useI18next();

  return (
    <div className={styles.main}>
      <SEO
        title={t('campaign-academy-title')}
        description={t('campaign-academy-description')}
        keywords={t('campaign-academy-keywords')}
        lang={'ja'}
        audience={'merchant'}
        ogImage={`${data.site.siteMetadata.siteUrl}${srcOgImage}`}
        twitterImage={`${data.site.siteMetadata.siteUrl}${srcOgImage}`}
      />
      <div className={styles.hero}>
        <div className={styles.levelOne}>
          <div className={styles.green}>
            <div className={styles.greenContainer}>
              <img
                src={srcGreenText}
                width={94}
                className={cx(styles.greenText)}
                alt={'もったいない。'}
              />
            </div>
          </div>
          <div className={styles.levelTwo}>
            <div className={styles.pink}>
              <div className={styles.pinkContainer}>
                <img
                  src={srcPinkText}
                  height={52}
                  className={cx(styles.pinkText)}
                  alt={'いいものなのに'}
                />
              </div>
              <div className={styles.place} />
            </div>
            <div className={styles.purple}>
              <div className={styles.purpleContainer}>
                <img
                  src={srcPurpleText}
                  height={52}
                  className={cx(styles.purpleText)}
                  alt={'売れないなんて。'}
                />
              </div>
              <div className={styles.place} />
            </div>

            <div className={styles.levelThree}>
              <div className={styles.heroMain}>
                <div className={styles.heroShout}>
                  <img
                    src={srcHeroShout}
                    width={478}
                    className={cx(styles.heroShoutText)}
                    alt={t('campaign-academy-hero-shout')}
                  />
                </div>
                <h1>
                  <img
                    src={srcHeroTitle}
                    width={560}
                    className={cx(styles.heroTitleText)}
                    alt={t('campaign-academy-hero-title')}
                  />
                </h1>
                <h2>
                  <img
                    src={srcHeroSubtitle}
                    width={406}
                    className={cx(styles.heroSubtitleText)}
                    alt={t('campaign-academy-hero-subtitle')}
                  />
                </h2>
                <div>
                  <a
                    href="https://academy.smartpay.co/bundles/ec-academy"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.button}
                    data-gtm-click="academy_1st_banner"
                  >
                    {t('campaign-academy-register-button')}
                  </a>
                </div>
                <img
                  src={srcCopnversionUpText}
                  width={347}
                  className={cx(styles.heroPromoteText)}
                  alt={t('campaign-academy-hero-conversation-up')}
                />
                <img
                  src={srcLogo}
                  width={347}
                  className={styles.logo}
                  alt={'Smartpay（スマートペイ）'}
                />
              </div>
              <div className={styles.yellow}>
                <div className={styles.yellowContainer}>
                  <img
                    src={srcYellowText}
                    width={93}
                    className={cx(styles.yellowText)}
                    alt={'報奨金一千万円 コンペ開催中!'}
                  />
                  <img
                    src={srcYellowTextMobile}
                    height={60}
                    className={cx(styles.yellowTextMobile)}
                    alt={'報奨金一千万円 コンペ開催中!'}
                  />{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.about}>
        <h2>{t('campaign-academy-about-title')}</h2>
        <p>
          <Trans i18nKey="campaign-academy-about-desc" />
        </p>
        <h3 className={styles.partnerTitle}>{t('campaign-academy-partner')}</h3>
        <div className={styles.partners}>
          <img
            src={srcPartnerSMBC}
            className={styles.partner}
            loading="lazy"
            alt={'SMBC VC'}
          />
        </div>
        <h3 className={styles.partnerTitle}>{t('campaign-academy-assoc')}</h3>
        <div className={styles.partners}>
          <img
            src={srcPartnerShopify}
            className={styles.partner}
            loading="lazy"
            alt={'Shopify'}
          />
          <img
            src={srcPartnerECCube}
            className={styles.partner}
            loading="lazy"
            alt={'EC Cube'}
          />
        </div>
        <h3 className={styles.partnerTitle}>{t('campaign-academy-methods')}</h3>
        <div className={styles.partners}>
          <img
            src={srcPartnerVISA}
            className={cx(styles.partner, styles.visa)}
            loading="lazy"
            alt={'Visa'}
          />
          <img
            src={srcPartnerMaster}
            className={cx(styles.partner, styles.visa)}
            loading="lazy"
            alt={'Master'}
          />
          <img
            src={srcPartnerApplePay}
            className={styles.partner}
            loading="lazy"
            alt={'Apple Pay'}
          />
          <img
            src={srcPartnerGooglePay}
            className={styles.partner}
            loading="lazy"
            alt={'Google Pay'}
          />
        </div>
      </div>
      <div className={styles.about2}>
        <h3>
          <Trans i18nKey="campaign-academy-about-sec2-title" />
        </h3>
        <p>
          <Trans i18nKey="campaign-academy-about-sec2-questions" />
        </p>
        <p>
          <Trans i18nKey="campaign-academy-about-sec2-desc" />
        </p>
        <div className={styles.mods}>
          <div className={styles.mod}>
            <span className={styles.prefix}>MODULE</span>
            <b>01</b>
            <h4>{t('campaign-academy-about-mod1-title')}</h4>
            <p>
              <Trans i18nKey="campaign-academy-about-mod1-desc" />
            </p>
          </div>
          <div className={styles.mod}>
            <span className={styles.prefix}>MODULE</span>
            <b>02</b>
            <h4>{t('campaign-academy-about-mod2-title')}</h4>
            <p>
              <Trans i18nKey="campaign-academy-about-mod2-desc" />
            </p>
          </div>
          <div className={styles.mod}>
            <span className={styles.prefix}>MODULE</span>
            <b>03</b>
            <h4>{t('campaign-academy-about-mod3-title')}</h4>
            <p>
              <Trans i18nKey="campaign-academy-about-mod3-desc" />
            </p>
          </div>
        </div>
        <h3>{t('campaign-academy-about-omni-assoc')}</h3>
        <img
          src={srcZeroFee}
          width={352}
          className={styles.zeroFee}
          loading="lazy"
          alt={t('campaign-academy-about-0fee')}
        />
        <a
          href="https://academy.smartpay.co/bundles/ec-academy"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.button}
          data-gtm-click="academy_2nd_banner"
        >
          {t('campaign-academy-register-button')}
        </a>
      </div>
      <div className={styles.comment}>
        <div className={styles.rainbowHead}>
          <h3 className={styles.commentShout}>
            {t('campaign-academy-comment-shout')}
          </h3>
        </div>
        <div className={styles.videoContainer}>
          <img src={srcFakeVideo} width={352} className={styles.commentVideo} />
        </div>
      </div>
      <div className={styles.competition}>
        <div className={styles.main}>
          <h2>
            <img
              src={srcLearnAndSale}
              width={440}
              className={styles.learnAndSale}
              loading="lazy"
              alt={
                t('campaign-academy-competition-learn') +
                t('campaign-academy-competition-sale') +
                '1000' +
                t('campaign-academy-man') +
                t('campaign-academy-yen') +
                t('campaign-academy-competition-sale-up-title')
              }
            />
          </h2>
          <p>
            <Trans i18nKey="campaign-academy-competition-sale-up-desc" />
          </p>
          <div className={styles.aboutTerms}>
            <Trans i18nKey="campaign-academy-competition-terms-link">
              業績アップコンペの参加規約は
              <Link
                to="/academy/terms"
                state={{ back: true }}
                className={styles.link}
              >
                こちら
              </Link>
            </Trans>
          </div>
          <div className={styles.agreeTermsAnd}>
            {t('campaign-academy-competition-agree-terms')}
          </div>
          <Link
            to="./register"
            className={styles.button}
            data-gtm-click="academy_3rd_banner"
          >
            {t('campaign-academy-competition-join-campaign')}
          </Link>

          <img
            src={srcVoiceA}
            width={356}
            className={cx(styles.voice, styles.desktop, styles.voiceA)}
            loading="lazy"
            alt={t('campaign-academy-competition-a')}
          />
          <img
            src={srcVoiceB}
            width={346}
            className={cx(styles.voice, styles.desktop, styles.voiceB)}
            loading="lazy"
            alt={t('campaign-academy-competition-b')}
          />
          <img
            src={srcVoiceC}
            width={278}
            className={cx(styles.voice, styles.desktop, styles.voiceC)}
            loading="lazy"
            alt={t('campaign-academy-competition-c')}
          />
          <img
            src={srcVoiceD}
            width={245}
            className={cx(styles.voice, styles.desktop, styles.voiceD)}
            loading="lazy"
            alt={t('campaign-academy-competition-d')}
          />

          <img
            src={srcVoiceMobileA}
            width={327}
            className={cx(styles.voice, styles.mobile, styles.voiceA)}
            alt={t('campaign-academy-competition-a')}
          />
          <img
            src={srcVoiceMobileB}
            width={327}
            className={cx(styles.voice, styles.mobile, styles.voiceB)}
            loading="lazy"
            alt={t('campaign-academy-competition-b')}
          />
          <img
            src={srcVoiceMobileC}
            width={327}
            className={cx(styles.voice, styles.mobile, styles.voiceV)}
            loading="lazy"
            alt={t('campaign-academy-competition-c')}
          />
          <img
            src={srcVoiceMobileD}
            width={327}
            className={cx(styles.voice, styles.mobile, styles.voiceD)}
            loading="lazy"
            alt={t('campaign-academy-competition-d')}
          />
        </div>
      </div>
      <div className={styles.vision}>
        <img
          src={srcVisionTitle}
          className={styles.visionTitle}
          width={347}
          loading="lazy"
          alt={t('campaign-academy-vision-our-vision')}
        />
        <img
          src={srcVisionLogo}
          className={styles.visionLogo}
          width={357}
          loading="lazy"
          alt={''}
        />
        <div className={styles.videoContainer}>
          <video
            controls
            width="720"
            height="405"
            preload="auto"
            poster={srcVision}
          >
            <source
              src="https://assets.smartpay.co/videos/smartpay-value/merchant-smartpay-value-shopify.webm"
              type="video/webm"
            />
            <source
              src="https://assets.smartpay.co/videos/smartpay-value/merchant-smartpay-value-shopify.mp4"
              type="video/mp4"
            />
            <source
              src="https://assets.smartpay.co/videos/smartpay-value/merchant-smartpay-value-shopify.ogv"
              type="video/ogg"
            />
          </video>
        </div>
      </div>
      <Footer t={t} />
    </div>
  );
};

export default CampaignLandingAcademyPage;
